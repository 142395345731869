@import '../../styles/variables.scss';

.chemic-modal {
  .ant-modal-header {
    background-color: $chemic-colors-main;

    .ant-modal-title {
      color: white;
    }
  }
}
