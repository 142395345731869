$chemic-colors-main: #28a745;
$chemic-colors-main-9: lighten($chemic-colors-main, 5%);
$chemic-colors-main-4: lighten($chemic-colors-main, 15%);
$chemic-colors-main-1: lighten($chemic-colors-main, 40%);
$chemic-colors-main-disabled: #30d457;
$chemic-colors-main-enabled: #1c9638;

$colors-error: #ff4d4f;

$colors-black-10: rgba(0, 0, 0, 0.1);
$colors-black-25: rgba(0, 0, 0, 0.25);
$colors-black-45: rgba(0, 0, 0, 0.45);

$colors-disabled: $colors-black-25;

@mixin base-shadow {
  box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
}

/// Высота линии для заголовка компоненте Group
$group-title-line-height: 25px;
