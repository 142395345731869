@import './styles/variables.scss';

#root {
  background-color: #ffffff;
}

html,
body {
  height: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.always-on-top {
  z-index: 100;
}

textarea {
  resize: none;
}

.no-word-wrap {
  white-space: nowrap;
}

.white-space-preline {
  white-space: pre-line;
}

.icon_disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.non-decorated-link {
  color: inherit;
  transition: none;

  &:hover {
    color: inherit;
  }
}

.list-style-none {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
